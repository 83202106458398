import './commanditaire.css';
import React, { useState, useEffect, useRef } from 'react';
import arrow from './../../logo/arrow-left 1.png'
import cubic from './../../logo/Cubic.png'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';



function Commanditaire() {

  const ref = useRef()
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ["start start", "end start"]
  })

  const yBg = useTransform(scrollYProgress, [0,1], ["0%", "100%"])
  return (
    <div className="commanditaire-container" ref={ref}>
        <div className='commanditaire-text-container'>
            <div className='commandiraire-title'>CONFÉRENCIER</div>
            <div className='text-indent'>
            <div className='commanditaire-sub'> QUOI SAVOIR</div>
            <div className='commanditaire-text'>Une présentation qui tourne autour de votre spécialisation, entreprise, exploits, expériences, ambitions, et anecdotes.</div>
            <div className='commanditaire-text-orange'>Durée de  1h + période de question 
Moment réseautage</div>
            <div className='commanditaire-sub'>PLUS D'INFO</div>
            <div className='commanditaire-text-orange-date'>
L'événement vise à permettre aux étudiants d'apprendre des professionnels de l'industrie et de nouer des relations de mentorat. Les présentations doivent mettre en avant l'expérience et les réalisations des intervenants pour inspirer les participants.</div>
<div className='commanditaire-text-orange-sub'>Nous voulons ramener la culture du ''mentorship'' à travers tous cela!</div>
        </div>
        </div>
    </div>
  );
}

export default Commanditaire;
