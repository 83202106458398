import './App.css';
import Commanditaire from './Component/Commanditaire/commanditraire';
import Contact from './Component/Contact/contact';
import Equipe from './Component/Equipe/equipe';
import Fondateur from './Component/Fondateur/fondateur';
import Objectif from './Component/Objectif/objectif';
import Footer from './Component/footer/footer';
import Intro from './Component/intro/intro';
import Navbar from './Component/navbar/navbar';


function App() {
  return (
    <div className="App">
      <section id='Navbar'><Navbar></Navbar></section>
      <section id='Intro'><Intro></Intro></section>
      
      <section id='Objectif'><Objectif></Objectif></section>
      <section id='Equipe'><Equipe></Equipe></section>
      <section id='Fondateur'><Fondateur></Fondateur></section>
      <section id='Conférencier'><Commanditaire></Commanditaire></section>
      <section id='Contact'><Contact></Contact></section>
      <section id='Footer'><Footer></Footer></section>
    </div>
  );
}

export default App;
