import './contact.css';
import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';

function Contact() {
  const formRef = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_rjzrnlj', 'template_j3kjxj6', formRef.current, 'KkghyDwreAbkKxkXl')
      .then((result) => {
        console.log(result);
        setShowPopup(true); // Show popup when form is successfully submitted
        formRef.current.reset();
        setTimeout(() => {
          setShowPopup(false); // Hide popup after 2 seconds
        }, 2000);
      }, (error) => {
        console.log(error);
      });
  };

  return (
    <div className="contact-container">
      <div className='contact-text-container'>
        <div className='contact-title'>NOUS CONTACTER</div>
        <div className='contact-text'>Pour toute question, suggestion ou collaboration, n'hésitez pas à nous contacter.</div>
        <div className='contact-text'>Utilisez le formulaire ci-dessous et nous vous répondrons dès que possible. Nous sommes impatients de vous entendre ! </div>
        <div className='contact-text'>- L'équipe de 3D Connecte</div>
      </div> 
      <div className='contact-form-container'>
      {showPopup && (
            <motion.div 
              className="popup"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
            >
              Votre message a été envoyé avec succès!
            </motion.div>
          )}
        <form className='contact-form' ref={formRef} onSubmit={sendEmail}>
          <input type='text' name="user_name" required placeholder='Nom' className='contact-nom'></input>
          <input type='email' name="user_email" required placeholder='Courriel' className='contact-email'></input>
          <textarea name="message" id="inputcommentaire" className="contact-form-text" rows="8" required="required" placeholder='Commentaire'></textarea>
          <button className='contact-button' value="Send">Envoyer</button>
        </form>
      </div>
    </div>
  );
}
export default Contact;
