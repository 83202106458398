import './intro.css';
import logo3d from './../../logo/3dconnectlogo.png'
function Intro() {
  return (
    <div className="intro-container">
        <div className='intro-container-object'>
        <div className='intro-image-container'>
            <img src={logo3d} alt='logo3d' className='intro-image'></img>
        </div>
        <div className='intro-text-container'>
            <div className='intro-title'>ÉVÉNEMENT DE RÉSEAUTAGE ET D'OPPORTUNITÉ!</div>
            <div className='intro-sub'>Multiples informations sur les différentes spécialisations de l’industrie de la 3D </div>
            <div className='intro-subbold'>Des conférences présentés par des gens de l’industrie</div>
        </div>
        </div>
    </div>
  );
}

export default Intro;