import './togglebutton.css';
import hamburger from './../../../logo/hamburger.png';
function ToggleButton({setOpen}) {
  return (
    <button className="togglebutton-container" onClick={() => setOpen(prev=>!prev)}>
       <img src={hamburger} alt='' className='togglebutton-hamburger'></img>
    </button>
  );
}

export default ToggleButton;
