import { useState } from 'react';
import Links from './Links/links';
import './sidebar.css';
import ToggleButton from './toggleButton/togglebutton';
import { motion } from 'framer-motion';
function Sidebar() {
    const items = [ "Intro", "Objectif", "Equipe", "Fondateur", "Conférencier", "Contact", "Footer"]
    const [open, setOpen] = useState(false);
    const variant = {
        open: {
            clipPath: "circle(1200px at 50px 50px)",
            transitition:{
                delay:0.7,
                type:"spring",
                stifness:20,
            }
        },
        closed: {
            clipPath: "circle(30px at 50px 50px)",
            transitition:{
                delay:0.7,
                type:"spring",
                stifness:300,
                damping: 20,
            }
        }
    }
  return (
    <motion.div className="sidebar-container" animate={open ? "open" : "closed"}>
       <motion.div className='sidebar-bg' variants={variant}>
            <div className='sidebar-links'>
                {items.map(item=>(
                    <a href={`#${item}`} key={item}>
                        {item}
                    </a>
                ))}
            </div>
       </motion.div>
       <ToggleButton setOpen={setOpen}></ToggleButton>
    </motion.div>
  );
}

export default Sidebar;
