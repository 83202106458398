import './equipe.css';
import React, { useState } from 'react';
import background_photo from './../../picture/Blurred_Black_and_white.png'
import Hichem from './../../picture/Hichem.png'
import Heloise from './../../picture/Heloise.png'
import Edouard from './../../picture/Edouard.png'
import Justine from './../../picture/Justine.png'
import Karine from './../../picture/Karine.png'
import Maxime from './../../picture/Maxime.png'
import Timothe from './../../picture/Timothe.png'
import Victor from './../../picture/Victor.png'

function Equipe() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleCardHover = (index) => {
    setHoveredIndex(index);
  };

  const handleCardLeave = () => {
    setHoveredIndex(null);
  };
  return (
    <div className="equipe-container">
      <div className='equipe-title'>NOTRE ÉQUIPE</div>
      <div className='equipe-image'>
        <img src={background_photo} alt='' className='equipe-background-photo'></img>
        <img src={Hichem} alt="" className="equipe-Hichem-photo" style={{ opacity: hoveredIndex === 0 ? 1 : 0 }}></img> 
        <img src={Heloise} alt="" className="equipe-Heloise-photo" style={{ opacity: hoveredIndex === 1 ? 1 : 0 }}></img> 
        <img src={Edouard} alt="" className="equipe-Edouard-photo" style={{ opacity: hoveredIndex === 2 ? 1 : 0 }}></img> 
        <img src={Justine} alt="" className="equipe-Justine-photo" style={{ opacity: hoveredIndex === 3 ? 1 : 0 }}></img> 
        <img src={Karine} alt="" className="equipe-Karine-photo" style={{ opacity: hoveredIndex === 4 ? 1 : 0 }}></img> 
        <img src={Maxime} alt="" className="equipe-Maxime-photo" style={{ opacity: hoveredIndex === 5 ? 1 : 0 }}></img> 
        <img src={Timothe} alt="" className="equipe-Timothe-photo" style={{ opacity: hoveredIndex === 6 ? 1 : 0 }}></img> 
        <img src={Victor} alt="" className="equipe-Victor-photo" style={{ opacity: hoveredIndex === 7 ? 1 : 0 }}></img> 
      </div>
      <div className='equipe-web'>Zhiqin Yin Web Developer</div>
      <div className="equipe-carouselle">
      <div className='equipe-carouselle-container'>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(0)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Hichem M. Gervais</div>
          <div className="equipe-carouselle-Title"> Des événements inoubliables qui éclairent mon chemin et m'inspirent à grandir chaque jour.</div>
          <div className="equipe-carouselle-Quote">Président Comité Événementiel</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(1)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Heloise Rolland</div>
          <div className="equipe-carouselle-Title">Plus d’opportunités! Créons, explorons et saisissons chaque occasion qui se présente sur notre chemin.                                     </div>
          <div className="equipe-carouselle-Quote">Photographe</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(2)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Édouard Le Marchand</div>
          <div className="equipe-carouselle-Title">Trouvons notre unité et avançons ensemble vers nos objectifs communs.                                       </div>
          <div className="equipe-carouselle-Quote">Installateur</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(3)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Justine Gagnon</div>
          <div className="equipe-carouselle-Title">Dans les défis comme dans les succès, restons résolus et déterminés à aller de l'avant.                                     </div>
          <div className="equipe-carouselle-Quote">Installatrice</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(4)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Karine Bazerghi</div>
          <div className="equipe-carouselle-Title">Pour un futur stable, bâti sur des fondations solides de persévérance, de travail acharné et d'innovation.                                    </div>
          <div className="equipe-carouselle-Quote">Installatrice</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(5)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Maxime Picard</div>
          <div className="equipe-carouselle-Title">Fun First! Priorité à la joie, à l'enthousiasme et à la créativité dans tout ce que nous entreprenons.                                    </div>
          <div className="equipe-carouselle-Quote">Animateur</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(6)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Timothé Dessureault-Leblanc                                  </div>
          <div className="equipe-carouselle-Title">Soyons attentifs, ouverts et prêts à saisir chaque opportunité qui se présente.</div>
          <div className="equipe-carouselle-Quote">Installateur</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(7)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Victor Bériau</div>
          <div className="equipe-carouselle-Title">Au NAD, on s’entraide dans une culture de collaboration et de soutien mutuel.                                    </div>
          <div className="equipe-carouselle-Quote">Graphic Desginer</div>
        </div>
      </div>
      <div className='equipe-carouselle-container'>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(0)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Hichem M. Gervais</div>
          <div className="equipe-carouselle-Title"> Des événements inoubliables qui éclairent mon chemin et m'inspirent à grandir chaque jour.</div>
          <div className="equipe-carouselle-Quote"> Président Comité Événementiel</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(1)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Héloise Rolland</div>
          <div className="equipe-carouselle-Title">Plus d’opportunités! Créons, explorons et saisissons chaque occasion qui se présente sur notre chemin.                       </div>
          <div className="equipe-carouselle-Quote">Photographe</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(2)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Édouard Le Marchand</div>
          <div className="equipe-carouselle-Title">Trouvons notre unité et avançons ensemble vers nos objectifs communs.                      </div>
          <div className="equipe-carouselle-Quote">Installateur</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(3)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Justine Gagnon</div>
          <div className="equipe-carouselle-Title">Dans les défis comme dans les succès, restons résolus et déterminés à aller de l'avant.                        </div>
          <div className="equipe-carouselle-Quote">Installatrice</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(4)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Karine Bazerghi</div>
          <div className="equipe-carouselle-Title">Pour un futur stable, bâti sur des fondations solides de persévérance, de travail acharné et d'innovation. </div>
          <div className="equipe-carouselle-Quote">Installatrice</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(5)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Maxime Picard</div>
          <div className="equipe-carouselle-Title">Fun First! Priorité à la joie, à l'enthousiasme et à la créativité dans tout ce que nous entreprenons.  </div>
          <div className="equipe-carouselle-Quote">Animateur</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(6)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Timothé Dessureault-Leblanc</div>
          <div className="equipe-carouselle-Title">Soyons attentifs, ouverts et prêts à saisir chaque opportunité qui se présente.</div>
          <div className="equipe-carouselle-Quote">Installateur</div>
        </div>
        <div className="equipe-carouselle-card" onMouseEnter={() => handleCardHover(7)} onMouseLeave={handleCardLeave}>
          <div className="equipe-carouselle-Name">Victor Bériau</div>
          <div className="equipe-carouselle-Title">Au NAD, on s’entraide dans une culture de collaboration et de soutien mutuel.</div>
          <div className="equipe-carouselle-Quote">Graphic Desginer</div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Equipe;
