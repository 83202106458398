import './objectif.css';
import React, { useState, useRef } from 'react';
import arrow from './../../logo/arrow-left 1.png'
import cubic from './../../logo/Cubic.png'
import visibilite from './../../logo/eye (1).png'
import renseignement from './../../logo/customer-service.png'
import mentorship from './../../logo/mentorship.png'
import Expérience from './../../logo/quality.png'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
function Objectif() {
  const boxContents = [
    {
      image: Expérience,
      title: 'Expérience',
      text: "Mettre en lumière les expériences et les exploits des acteurs de l'industrie, en honorant leurs parcours exceptionnels et en soulignant l'impact inspirant qu'ils ont sur leur domaine.",
      subtext: 'Succès et Parcours',
    },
    {
      image: mentorship,
      title: 'Mentorship',
      text: "Nouer des liens forts entre étudiants et spécialistes de l’industrie pour but de donner des possibilités de mentorship.",
      subtext: 'Liens Forts',
    },
    {
      image: renseignement,
      title: 'Renseignement',
      text: 'Guidez les artistes vers des carrières enrichissantes dans la 3D en leur faisant découvrir ses multiples spécialisations, alignées avec leurs passions et compétences.',
      subtext: 'Spécialisations 3D',
    },
    {
      image: visibilite,
      title: 'Visibilité',
      text: "Nous nous engageons pour l'équité et l'inclusion en tant qu'événement à but non lucratif.",
      subtext: 'Non-lucratif',
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (direction) => {
    const newPosition =
      direction === 'right'
        ? Math.min(scrollPosition + 1, boxContents.length - 1)
        : Math.max(scrollPosition - 1, 0);

    setScrollPosition(newPosition);
  };
  const ref = useRef()
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ["start start", "end start"]
  })

  const yBg = useTransform(scrollYProgress, [0,1], ["0%", "150%"])
  

  return (
    <div className="objectif-container" ref={ref}>
      <div className="objectif-title">NOS OBJECTIFS</div>
      <div className="objectif-box-outer-container">
        <div className="objectif-box-inner-container" style={{ transform: `translate(${-scrollPosition * 520}px)` }}>
          {boxContents.map((box, index) => (
            <div className="objectif-box" key={index}>
              <img src={box.image} alt="" className="objectif-box-image"></img>
              <div className="objectif-box-title">{box.title}</div>
              <div className="objectif-box-text">{box.text}</div>
              <div className="objectif-box-sub">{box.subtext}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="objectif-arrow-container">
        <button className='objectif-arrow-left' onClick={() => handleScroll('left')}><img src={arrow} alt='' className='objectif-left-arrow'></img></button>
        <button className='objectif-arrow-right' onClick={() => handleScroll('right')}><img src={arrow} alt='' className='objectif-right-arrow'></img></button>
      </div>
      <motion.div className='objectif-cubic' style={{y: yBg}}>
        <img src={cubic} alt='' className='objectif-cubic-image'></img>
      </motion.div>
    </div>
  );
}

export default Objectif;
