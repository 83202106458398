import './navbar.css';
import logo from './../../logo/Group 33.png'
import Sidebar from '../sidebar/sidebar';
function Navbar() {
  return (
    <div className="navbar-container">
       <div className='navbar-image-container'>
        <Sidebar></Sidebar>
        <img src={logo} alt='' className='navbar-image'></img>
        </div> 
      <div className='navbar-link-container'>
        <div className='navbar-link'><a href='#Objectif'>Objectifs</a></div>
        <div className='navbar-link'><a href='#Equipe'>Equipe</a></div>
        <div className='navbar-link'><a href='#Conférencier'>Conférencier</a></div>
        <div className='navbar-link'><a href='#Contact'>Contact</a></div>
      </div>
    </div>
  );
}

export default Navbar;
