import './footer.css';
import letterc from'./../../logo/letter-c (1) 1.png'
import logo3D from './../../logo/3dconnectlogo.png'
import linkedIn from './../../logo/linkedin 1.png'
import AEN from './../../logo/logo_ae 2.png'
function Footer() {
  return (
    <div className="footer-container">
      <div className='footer-rights'>
      Tous les droits réservés 
      <img src={letterc} alt='' className='footer-rights-img'></img>
       3DConnecte</div>
      <div className='footer-logo'>
        <img src={logo3D} alt='' className='footer-logo3D'></img>
      </div>
      <div className='footer-AEN'>
        <img src={AEN} alt='' className='footer-logo3D'></img>
      </div>
      <div className='footer-social'>
        <div className='footer-email'>Email: HGervais@etu.uqac.ca</div>
        <div className='footer-cell'>Cell: +1(514)549-6705</div>
      </div>
      <div className='footer-contact'>
        <div className='footer-contact-social1'>
        <a href='https://www.linkedin.com/in/hichem-mickeal-gervais/?originalSubdomain=ca'>
          <img src={linkedIn} alt=''></img></a>
        </div>
        {/* <div className='footer-contact-social1'>
          <img src={linkedIn} alt=''></img>
        </div> */}
      </div>
      <div className='footer-links'>
        
      </div>
    </div>
  );
}

export default Footer;
