import "./fondateur.css";
import fondateur from "./../../logo/Fondateur.png";
import quoted from "./../../logo/quote-right 1.png";
import { motion } from "framer-motion";
import React, { useRef } from 'react';
import { useScroll, useTransform } from 'framer-motion';
import cubic from './../../logo/Cubic.png'

const variants = {
  initial: {
    y: 200,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      staggerChildren: 0.1,
      delay: 0.5,
      type: 'spring', // Use spring easing
      stiffness: 100, // Adjust stiffness
      damping: 10, // Adjust damping
    },
  },
  exit: {
    opacity: 1,
    y: 0, // Set the y value to match the initial y position
  },
};
const variants1 = {
  initial: {
    y: 200,
    opacity: 0,
    zIndex: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 1,
    y: 0, // Set the y value to match the initial y position
  },
};



function Fondateur() {

  const ref1 = useRef()
  const {scrollYProgress} = useScroll({
    target: ref1,
    offset: ["start start", "end start"]
  })

  const yBg = useTransform(scrollYProgress, [0,1], ["0%", "90%"])
  return (
    <div className="fondateur-container" ref={ref1}>
      <div className="fondateur-container-inside">
      <div className="fondateur-title">FONDATEUR DE 3D CONNECTE</div>
      <motion.div
        className="fondateur-image-container"
        variants={variants}
        initial="initial"
        whileInView="animate"
        exit="initial"
      >
        <img src={fondateur} alt="" className="fondateur-image"></img>
        <div className="fondateur-orange"></div>
      </motion.div>
      <motion.div
        className="fondateur-quote-container"
        variants={variants1}
        initial="initial"
        whileInView="animate"
        exit="initial"
      >
        <div className="fondateur-white"></div>
        <img src={quoted} alt="" className="fondateur-quoted"></img>
        <div className="fondateur-quote">
          “J’ai décidé d’entreprendre le 3D Connecte avec l’idée de donner des opportunités de tous genre aux étudiants de l’industrie du CG. J’imagine cet événement aillant la possibilité de devenir un joueur majeur au niveau du réseautage. J’espère que les gens pourront nouer des liens fort et créer des amitiés opportunes. “
        </div>
        <div className="fondateur-Name">HICHEM M. GERVAIS</div>
        <div className="fondateur-position">
          Coordinateur événementiel de l’AEN | NAD-UQAC
        </div>
        <div className="fondateur-bar"></div>
      </motion.div>
      </div>
      <motion.div className='fondateur-cubic' style={{y: yBg}}>
        <img src={cubic} alt='' className='fondateur-cubic-image'></img>
      </motion.div>
    </div>
  );
}

export default Fondateur;
